
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#ab4c13',
    },
    secondary: {
      main: '#fff000'
    },
  },
  h1: {
    color: 'white',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  icon: {
    color: 'white',
  },
});

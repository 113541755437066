import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import { blue } from '@material-ui/core/colors'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '1rem',
    minWidth: 200,
  },
}))

const SettingsDialog = ({ t, onClose, language, currency, setLanguage, setCurrency, open }) => {
  const classes = useStyles()

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  }

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  }

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">{t('settings.language')}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={language}
          onChange={handleLanguageChange}
          label="language"
        >
          <MenuItem value={'en-US'}>English US</MenuItem>
          <MenuItem value={'PT'}>Portugal PT</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">{t('settings.currency')}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={currency}
          onChange={handleCurrencyChange}
          label="currency"
        >
          <MenuItem value={'EUR'}>EUR - €</MenuItem>
          <MenuItem value={'GBP'}>GPD - £</MenuItem>
          <MenuItem value={'USD'}>USD - $</MenuItem>
        </Select>
      </FormControl>
    </Dialog>
  )
}

export default withTranslation()(SettingsDialog)

import 'date-fns'
import React, { useEffect, useState} from 'react'
import DateFnsUtils from '@date-io/date-fns'
import ptLocale from 'date-fns/locale/pt';
import enLocale from 'date-fns/locale/en-US';
import { withTranslation } from 'react-i18next'

import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers'

const localeMap = {
  pt: ptLocale,
  'en-US': enLocale,
}

const NewDatePicker = ({ t, setDate, label, minDate, maxDate }) => {
  const [selectedDate, setSelectedDate] = useState(null)

  const handleDateChange = (date) => {
    setSelectedDate(date)
    if (isNaN(+date)) return
    setDate(date.toISOString().split('T')[0])
  }

  useEffect(() => {
    (() => {
      const today = new Date();

      if (minDate && new Date(minDate) > today) {
        setSelectedDate(new Date(minDate))
      } else if (maxDate && new Date(maxDate) < today) {
        setSelectedDate(new Date(maxDate))
      }
    })()
  }, [minDate, maxDate])

// disablePast disableFuture maxDate maxDateMessage minDate minDateMessage
  const dateLimits = {}

  if (minDate) dateLimits.minDate = minDate
  if (maxDate) dateLimits.maxDate = maxDate

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['pt']}>
      <DatePicker
        {...dateLimits}
        autoOk
        label={label}
        disablePast
        format="dd/MM/yyyy" // TODO: make it depend on locale
        value={selectedDate}
        cancelLabel={t('global.cancel')}
        locale={'PT'}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  )
}

export default withTranslation()(NewDatePicker)

import React from 'react'
import {
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  img: {
    width: 100,
  }
}));

const Flight = ({ t, name, id, city1, city2, price1, price2, dateFrom, dateTo, currencyFormatter }) => { // TODO currency from response
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {/* <Grid item>
        <img className={classes.img} alt="complex" src={imageUrl} />
      </Grid> */}
      <Grid item>
        <Typography variant="h6">{`${name} - ${currencyFormatter.format(price1 + price2)}`}</Typography>
        <Typography><Link href={`https://www.skyscanner.net/transport/flights/${city1.replace('-sky', '')}/${id}/${dateFrom}/${dateTo}/`} target="_blank" rel="noopener">{`${t('results.from')} ${city1.replace('-sky', '')} - ${currencyFormatter.format(price1)}`}</Link></Typography>
        <Typography><Link href={`https://www.skyscanner.net/transport/flights/${city2.replace('-sky', '')}/${id}/${dateFrom}/${dateTo}/`} target="_blank" rel="noopener">{`${t('results.from')} ${city2.replace('-sky', '')} - ${currencyFormatter.format(price2)}`}</Link></Typography>
      </Grid>
    </Grid>
  )
}

export default withTranslation()(Flight)

import IconButton from '@material-ui/core/IconButton';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    ...theme.icon,
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const ThemeSwitch = ({ theme, switchTheme }) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.icon} color="primary" aria-label="upload picture" component="span" onClick={switchTheme}>
      {theme === 'light' ? <NightsStayIcon /> : <WbSunnyIcon /> }
    </IconButton>
  )
}

export default ThemeSwitch

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  copyright: {
    fontSize: 10,
    position: 'fixed',
    bottom: 3,
    right: 3,
  },
}))

const Copyright = () => {
  const classes = useStyles()

  return (
    <span className={classes.copyright}>
      © Rui Soares, 2021
    </span>
  )
}

export default Copyright

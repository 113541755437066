import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings'
import IconButton from '@material-ui/core/IconButton';
import SettingsDialog from './SettingsDialog'

const useStyles = makeStyles((theme) => ({
  icon: {
    ...theme.icon,
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

const Settings = ({ language, currency, setLanguage, setCurrency }) => {
  const [open, setOpen] = React.useState(false)

  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <IconButton className={classes.icon} color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen}>
        <SettingsIcon />
      </IconButton>
      <SettingsDialog
        language={language}
        currency={currency}
        setLanguage={setLanguage}
        setCurrency={setCurrency}
        open={open}
        onClose={handleClose}
      />
    </div>
  )
}

export default Settings

import React, { useState, useEffect, useContext } from 'react'
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { countryCodeEmoji } from 'country-code-emoji'
import { withTranslation } from 'react-i18next'

import calculateHalfway from '../lib/calculateHalfway'
import SettingsContext from '../contexts/SettingsContext'
import Flight from './Flight'

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.h5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
}))

const Results = ({ t, city1, city2, dateFrom, dateTo }) => {
  const classes = useStyles()
  const { language, currency } = useContext(SettingsContext)
  const [countries, setCountries] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    calculateHalfway(city1, city2, dateFrom, dateTo, language, currency)
    .then(data => {
      setCountries(data)
      setLoading(false)
    })
  }, [city1, city2, dateFrom, dateTo, currency, language])

  // TODO: Add currency

  if (loading) return (<CircularProgress />)

  if (countries.length === 0) return (
    <Container>
      <Typography variant="h5">{t('results.noResults')}</Typography>
    </Container>
  )

  const currencyFormatter = new Intl.NumberFormat(language, { style: 'currency', currency, maximumFractionDigits: 0, minimumFractionDigits: 0 })

  return (
    <Container>
      <Box pb={1}>
        {countries.map((country) => {
          const { cities, name, price: cheapestPrice, code } = country

          return (
            <Accordion key={code}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.title} variant="h5">{countryCodeEmoji(code === 'UK' ? 'GB' : code)} {name} - {currencyFormatter.format(cheapestPrice)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {cities.map((c) =>
                  <Flight
                    key={c.code}
                    id={c.code}
                    name={c.name}
                    city1={city1}
                    city2={city2}
                    price1={c.priceCity1}
                    price2={c.priceCity2}
                    // imageUrl={f.ImageUrl}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    currencyFormatter={currencyFormatter}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Box>
    </Container>
  )
}

export default withTranslation()(Results)

import React from 'react'
import { Button, Grid, Box, Typography } from '@material-ui/core'
import { FlightTakeoff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import SettingsContext from '../contexts/SettingsContext'

import SearchBar from './SearchBar'
import DatePicker from './DatePicker'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '80vh',
    justify: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      height: '80vh',
    },
  },
  containerWithResults: {
    justify: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  header: {
    marginTop: 20,
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  title: {
    ...theme.h1,
    marginBottom: 0,
    textShadow: 'rgba(0, 0, 0, 0.6) 0px 2px 4px',
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
  },
  titleWithResults: {
    ...theme.h1,
    marginBottom: 0,
    textShadow: 'rgba(0, 0, 0, 0.6) 0px 2px 4px',
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      marginRight: '0.5rem',
    },
  },
  img: {
    height: 48,
    paddingBottom: 5,
  },
  imgWithResults: {
    height: 48,
    paddingBottom: 5,
    [theme.breakpoints.down('sm')]: {
      height: 24,
    },
  },
  datePickers: {
  },
  datePickersWithResults: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 5%',
    },
  },
  datePicker: {
  },
  datePickerWithResults: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  titleContainer: {
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
    },
  },
}))

const Header = ({
  t,
  city1,
  setCity1,
  city2,
  setCity2,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  showResults,
  search,
}) => {
  const classes = useStyles()

  return (
    <Grid
      className={showResults ? classes.containerWithResults : classes.container}
      container
      justify="center"
      alignItems="center"
    >
      <Grid
        className={classes.titleContainer}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Typography className={showResults ? classes.titleWithResults : classes.title} color="primary" variant="h2" component="h2" gutterBottom>
          Meet Me Halfway
        </Typography>
        <img className={showResults ? classes.imgWithResults : classes.img} alt="logo" src={'../logo.png'} />
      </Grid>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Box m={1}><SearchBar onSubmit={setCity1}/></Box>
        </Grid>
        <Grid item>
          <Box m={1}><SearchBar onSubmit={setCity2}/></Box>
        </Grid>
        <Grid
          item
        >
          <Grid
            className={showResults ? classes.datePickersWithResults : classes.datePickers}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item className={showResults ? classes.datePickerWithResults : classes.datePicker}>
              <Box m={1}><DatePicker setDate={setDateFrom} maxDate={dateTo} label={t('header.depart')}/></Box>
            </Grid>
            <Grid item className={showResults ? classes.datePickerWithResults : classes.datePicker}>
              <Box m={1}><DatePicker setDate={setDateTo} minDate={dateFrom} label={t('header.return')}/></Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<FlightTakeoff />}
            onClick={() => city1 && city2 && dateFrom && dateTo && search()}
            disabled={!city1 || !city2 || !dateFrom || !dateTo}
          >
            {t(`header.${showResults ? 'search' : 'letsmeet'}`)}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withTranslation()(Header)

import React, { useState } from 'react'
import { Header , Results } from './'
import { Grid } from '@material-ui/core'

function Hero() {
  const [city1, setCity1] = useState(null)
  const [city2, setCity2] = useState(null)
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [showResults, setShowResults] = useState(false)
  const [params, setParams] = useState({})

  const search = () => {
    setParams({
      city1,
      city2,
      dateFrom,
      dateTo,
    })
    setShowResults(true)
  }

  return (
    <Grid
      container
      direction="column"
      justify={showResults ? 'flex-start' : 'center'}
      alignItems="center"
    >
      <Header
        {...{
          city1,
          setCity1,
          city2,
          setCity2,
          dateFrom,
          setDateFrom,
          dateTo,
          setDateTo,
          showResults,
          search,
        }}
      />
      {showResults && (
        <Results {...params} />
      )}
    </Grid>
  )
}

export default Hero

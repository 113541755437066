import React, { useState, useContext } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next'
import SettingsContext from '../contexts/SettingsContext'

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: 300,
    backgroundColor: 'white',
  },
}));

const SearchBar = ({ t, onSubmit }) => {
  const [options, setOptions] = useState([])
  const classes = useStyles();
  const { language, currency } = useContext(SettingsContext)

  const fetchCities = (searchTerm) => {
    if (searchTerm.length === 0) return []
    fetch(`https://skyscanner-skyscanner-flight-search-v1.p.rapidapi.com/apiservices/autosuggest/v1.0/PT/${currency}/${language}/?query=${searchTerm}`, {
      headers: {
        "x-rapidapi-key": "54274bf476mshabd29d7dae6dd48p181232jsnb42765d372bd",
        "x-rapidapi-host": "skyscanner-skyscanner-flight-search-v1.p.rapidapi.com",
        "useQueryString": true
      }
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .then(data => setOptions(data.Places.filter((p) => p.PlaceId).map((p) => p)))
  }

  return (
    <Autocomplete
      classes={classes}
      id="combo-box-demo"
      options={options.map((o) => o.PlaceId)}
      noOptionsText={t('header.noOptions')}
      onInputChange={(event, newValue) => {
        fetchCities(newValue)
      }}
      onChange={(event, newValue) => {
        if (newValue) {
          onSubmit(newValue)
        }
      }}
      getOptionLabel={(id) => {
        const option = options.find((o) => o.PlaceId === id)
        if (!option) return
        return `${option.PlaceName} (${option.PlaceId.replace('-sky', '')}) - ${option.CountryName}`
      }}
      renderInput={(params) => <TextField className={classes.textInput} {...params} label={t('header.city')} variant="outlined" />}
    />
  )
}

export default withTranslation()(SearchBar)

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import firebase from 'firebase/app'
import 'firebase/analytics'
import './lib/i18n'

firebase.initializeApp({
  apiKey: 'AIzaSyABxRWBI8X5mXR2wVXNcKu2ciGmjkAtJ8w',
  authDomain: 'halfflights.firebaseapp.com',
  projectId: 'halfflights',
  storageBucket: 'halfflights.appspot.com',
  messagingSenderId: '429486261714',
  appId: '1:429486261714:web:e9244a332f80dbe5ceb40b',
  measurementId: 'G-7MCDTERS59',
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

function getDirectFlights(d) {
  const direct = d.Routes.filter((q) => q.Price)

  return direct.map((f) => {
    const country = d.Places.find((p) => p.PlaceId === f.DestinationId);
    const cities = f.QuoteIds.map((q) => {
      const quote = d.Quotes.find((quo) => quo.QuoteId === q)
      const destination = d.Places.find((p) => p.PlaceId === quote.OutboundLeg.DestinationId);
      return {
        price: quote.MinPrice,
        name: destination.Name,
        airportCode: destination.SkyscannerCode,
        cityName: destination.CityName,
        id: destination.CityId,
      }
    })
    return {
      price: f.Price,
      name: country.Name,
      type: country.Type,
      code: country.SkyscannerCode,
      cities,
    }
  })
}

async function getForCity(city, dateFrom, dateTo, language, currency) {
  const url = `https://skyscanner-skyscanner-flight-search-v1.p.rapidapi.com/apiservices/browseroutes/v1.0/PT/${currency}/${language}/${city}/anywhere/${dateFrom}/${dateTo}?profile=minimalcityrollupwithnamesv2&include=image&apikey=8aa374f4e28e4664bf268f850f767535&isMobilePhone=false&isOptedInForPersonalised=true`
  const response = await fetch(url, {
    headers: {
      "x-rapidapi-key": "54274bf476mshabd29d7dae6dd48p181232jsnb42765d372bd",
      "x-rapidapi-host": "skyscanner-skyscanner-flight-search-v1.p.rapidapi.com",
      "useQueryString": true
    }
  })

  const json = await response.json()

  return getDirectFlights(json)
}

function totalPrice(a) {
  return a.priceCity1 + a.priceCity2
}

function sortTotalPrice(a, b) {
  return totalPrice(a) - totalPrice(b)
}

const calculateHalfway = async (city1, city2, dateFrom, dateTo, language, currency) => {
  const countriesCity1 = await getForCity(city1, dateFrom, dateTo, language, currency)
  const countriesCity2 = await getForCity(city2, dateFrom, dateTo, language, currency)

  const result = countriesCity1.reduce((acc, countryCity1) => {
    const countryCity2 = countriesCity2.find((c) => c.code === countryCity1.code)

    if (!countryCity2) return acc

    const cities = countryCity1.cities.reduce((acc, cityCity1) => {
      const citiesCity2 = countryCity2.cities.filter((c) => c.id === cityCity1.id)
      if (citiesCity2.length === 0) return acc

      const cheapest = citiesCity2.sort((a, b) => a.price - b.price)[0]

      const duplicate = acc.find((c) => c.code === cityCity1.id)

      if (duplicate) {
        if (duplicate.priceCity1 > cityCity1.price) {
          duplicate.priceCity1 = cityCity1.price
        }
      } else {
        acc.push({
          priceCity1: cityCity1.price,
          priceCity2: cheapest.price,
          name: cityCity1.cityName,
          code: cityCity1.id,
        })
      }

      return acc
    }, [])
    if (cities.length === 0) return acc

    const sorted = cities.sort(sortTotalPrice)
    acc.push({
      price: totalPrice(sorted[0]),
      name: countryCity1.name,
      code: countryCity1.code,
      cities: sorted,
    })
    return acc
  }, [])

  return result.sort((a, b) => a.price - b.price)
}

export default calculateHalfway

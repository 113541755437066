import React, { useState, createContext } from 'react'
import './App.css'
import { Hero, Settings, Copyright } from './components'
import { light, dark } from './themes'
import { ThemeProvider } from '@material-ui/core/styles'
import i18n from './lib/i18n';
import SettingsContext from './contexts/SettingsContext'

function App() {
  const [theme, setTheme] = useState('light')
  const [language, setLanguage] = useState('en-US')
  const [currency, setCurrency] = useState('EUR')

  const switchTheme = () => setTheme(theme === 'light' ? 'dark' : 'light')

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage)
    i18n.changeLanguage(newLanguage)
  }

  return (
    <div className="App">
      <SettingsContext.Provider value={{ language, currency }}>
        <ThemeProvider theme={theme === 'light' ? light : dark}>
          {/* <ThemeSwitch theme={theme} switchTheme={switchTheme} /> */}
          <Settings
            language={language}
            currency={currency}
            setLanguage={changeLanguage}
            setCurrency={setCurrency}
          />
          <Hero />
          <Copyright />
        </ThemeProvider>
      </SettingsContext.Provider>
    </div>
  )
}

export default App
